<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Checkboxes">
                    <template v-slot:description>
                        <div>
                            import { HbCheckbox } from 'hummingbird-aviary';
                        </div>
                        <div class="mt-2">
                            All of the same vuetify props, slots, &amp; events as "v-checkbox"" can be used here in "hb-checkbox" in addition to the custom ones which are listed at the bottom of this page. For the list of the vuetify available props, slots, &amp; events, go to the vuetify documentation link for "v-checkbox" here: <hb-link href="https://v2.vuetifyjs.com/en/api/v-checkbox/" target="_blank">https://v2.vuetifyjs.com/en/api/v-checkbox/</hb-link>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Checkbox Examples + Code" class="ma-0 pa-0">
            
            <hb-form label="Checkboxes" full>

                <HbCheckbox
                    v-model="checkbox1"
                    label="Basic checkbox"
                />

                <HbCheckbox
                    v-model="checkbox2"
                    label="Basic checkbox readonly unchecked"
                    readonly
                />

                <HbCheckbox
                    v-model="checkbox3"
                    label="Basic checkbox readonly checked"
                    readonly
                />

                <HbCheckbox
                    v-model="checkbox4"
                    label="Basic checkbox disabled unchecked"
                    disabled
                />

                <HbCheckbox
                    v-model="checkbox5"
                    label="Basic checkbox disabled checked"
                    disabled
                />
                
                <HbCheckbox
                    v-model="checkbox6"
                    label="Basic checkbox with tooltip"
                    tooltip-header="Tooltip Header"
                    tooltip-body="Tooltip Body"
                />
                
                <hb-checkbox v-model="checkbox7">
                    <template v-slot:label>
                        Basic checkbox using label slot instead of props
                    </template>
                </hb-checkbox>

                <hb-checkbox v-model="checkbox8">
                    <template v-slot:label>
                        Basic checkbox using label slot and tooltip slots instead of props
                    </template>
                    <template v-slot:tooltipHeader>
                        Tooltip Header
                    </template>
                    <template v-slot:tooltipBody>
                        Tooltip Body
                    </template>
                </hb-checkbox>
                
                <HbCheckbox
                    v-model="checkbox9"
                    label="Basic checkbox using description prop"
                    description="This is using the description prop"
                />
                
                <hb-checkbox
                    v-model="checkbox10"
                    label="Basic checkbox using description slot"
                    class="mb-0"
                >
                    <template v-slot:description>
                        This is using the description slot
                    </template>
                </hb-checkbox>

                <hb-checkbox
                    v-model="checkbox11"
                    class="mb-0"
                >
                    <template v-slot:content>
                        <v-row no-gutters>
                            <div class="hb-inline-sentence-text">
                                Checkbox with one input
                            </div>
                            
                            <HbTextField
                                condensed
                                box
                                medium
                                v-model="textField"
                                v-validate.disable="'max:45|email'"
                                data-vv-scope="default"
                                id="email"
                                name="email"
                                :error="errors.collect('default.email').length > 0"
                            />
                        </v-row>
                    </template>
                </hb-checkbox>
                
                <hb-checkbox
                    v-model="checkbox12"
                    class="mb-0"
                >
                    <template v-slot:content>
                        <v-row no-gutters>
                            <div class="hb-inline-sentence-text">
                                Checkbox with complex inputs
                            </div>

                            <HbSelect
                                condensed
                                box
                                medium
                                v-model="selectField"
                                v-validate.disable="'max:45'"
                                :items="['Office','Fax','None']"
                                data-vv-scope="default"
                                id="type"
                                name="type"
                                :error="errors.collect('default.type').length > 0"
                                placeholder="Select"
                            />

                            <div class="hb-inline-sentence-text">
                                some text in between
                            </div>

                            <HbTextField
                                condensed
                                box
                                medium
                                v-model="textField2"
                                v-validate.disable="'max:45'"
                                data-vv-scope="default"
                                id="phone"
                                name="phone"
                                :error="errors.collect('default.phone').length > 0"
                            />
                        </v-row>
                    </template>
                </hb-checkbox>
                
                <hb-checkbox
                    v-model="checkbox13"
                    tooltip-header="Tooltip Header"
                    tooltip-body="Tooltip Body"
                    tooltip-after-content
                    description="This is using the description prop"
                >
                    <template v-slot:content>
                        <v-row no-gutters>
                            <div class="hb-inline-sentence-text">
                                Another checkbox with complex inputs
                            </div>
                            
                            <HbSelect
                                condensed
                                box
                                small
                                v-model="selectField2"
                                v-validate.disable="'max:45'"
                                :items="['$','%']"
                                data-vv-scope="default"
                                id="type"
                                name="type"
                                :error="errors.collect('default.type').length > 0"
                            />
                            
                            <HbTextField
                                condensed
                                box
                                medium
                                v-model="textField3"
                                v-validate.disable="'max:45'"
                                data-vv-scope="default"
                                id="phone"
                                name="phone"
                                :error="errors.collect('default.phone').length > 0"
                            />
                                
                            <div class="hb-inline-sentence-text pr-0">
                                and then the end of the sentence.
                            </div>
                        </v-row>
                    </template>
                </hb-checkbox>

                <HbCheckbox
                    v-model="indeterminateCheckbox"
                    label="Indeterminate checkbox"
                    indeterminate
                />
            </hb-form>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbCheckbox
    v-model="checkbox1"
    label="Basic checkbox"
/>

&lt;HbCheckbox
    v-model="checkbox2"
    label="Basic checkbox readonly unchecked"
    readonly
/>

&lt;HbCheckbox
    v-model="checkbox3"
    label="Basic checkbox readonly checked"
    readonly
/>

&lt;HbCheckbox
    v-model="checkbox4"
    label="Basic checkbox disabled unchecked"
    disabled
/>

&lt;HbCheckbox
    v-model="checkbox5"
    label="Basic checkbox disabled checked"
    disabled
/>

&lt;HbCheckbox
    v-model="checkbox6"
    label="Basic checkbox with tooltip"
    tooltip-header="Tooltip Header"
    tooltip-body="Tooltip Body"
/>

&lt;hb-checkbox v-model="checkbox7">
    &lt;template v-slot:label>
        Basic checkbox using label slot instead of props
    &lt;/template>
&lt;/hb-checkbox>

&lt;hb-checkbox v-model="checkbox8">
    &lt;template v-slot:label>
        Basic checkbox using label slot and tooltip slots instead of props
    &lt;/template>
    &lt;template v-slot:tooltipHeader>
        Tooltip Header
    &lt;/template>
    &lt;template v-slot:tooltipBody>
        Tooltip Body
    &lt;/template>
&lt;/hb-checkbox>

&lt;HbCheckbox
    v-model="checkbox9"
    label="Basic checkbox using description prop"
    description="This is using the description prop"
/>

&lt;hb-checkbox
    v-model="checkbox10"
    label="Basic checkbox using description slot"
    class="mb-0"
>
    &lt;template v-slot:description>
        This is using the description slot
    &lt;/template>
&lt;/hb-checkbox>

&lt;hb-checkbox
    v-model="checkbox11"
    class="mb-0"
>
    &lt;template v-slot:content>
        &lt;v-row no-gutters>
            &lt;div class="hb-inline-sentence-text">
                Checkbox with one input
            &lt;/div>
            
            &lt;HbTextField
                condensed
                box
                medium
                v-model="textField"
                v-validate.disable="'max:45|email'"
                data-vv-scope="default"
                id="email"
                name="email"
                :error="errors.collect('default.email').length > 0"
            />
        &lt;/v-row>
    &lt;/template>
&lt;/hb-checkbox>

&lt;hb-checkbox
    v-model="checkbox12"
    class="mb-0"
>
    &lt;template v-slot:content>
        &lt;v-row no-gutters>
            &lt;div class="hb-inline-sentence-text">
                Checkbox with complex inputs
            &lt;/div>

            &lt;HbSelect
                condensed
                box
                medium
                v-model="selectField"
                v-validate.disable="'max:45'"
                :items="['Office','Fax','None']"
                data-vv-scope="default"
                id="type"
                name="type"
                :error="errors.collect('default.type').length > 0"
                placeholder="Select"
            />

            &lt;div class="hb-inline-sentence-text">
                some text in between
            &lt;/div>

            &lt;HbTextField
                condensed
                box
                medium
                v-model="textField2"
                v-validate.disable="'max:45'"
                data-vv-scope="default"
                id="phone"
                name="phone"
                :error="errors.collect('default.phone').length > 0"
            />
        &lt;/v-row>
    &lt;/template>
&lt;/hb-checkbox>

&lt;hb-checkbox
    v-model="checkbox13"
    tooltip-header="Tooltip Header"
    tooltip-body="Tooltip Body"
    tooltip-after-content
    description="This is using the description prop"
>
    &lt;template v-slot:content>
        &lt;v-row no-gutters>
            &lt;div class="hb-inline-sentence-text">
                Another checkbox with complex inputs
            &lt;/div>

            &lt;HbSelect
                condensed
                box
                small
                v-model="selectField2"
                v-validate.disable="'max:45'"
                :items="['$','%']"
                data-vv-scope="default"
                id="type"
                name="type"
                :error="errors.collect('default.type').length > 0"
            />

            &lt;HbTextField
                condensed
                box
                medium
                v-model="textField3"
                v-validate.disable="'max:45'"
                data-vv-scope="default"
                id="phone"
                name="phone"
                :error="errors.collect('default.phone').length > 0"
            />

            &lt;div class="hb-inline-sentence-text pr-0">
                and then the end of the sentence.
            &lt;/div>
        &lt;/v-row>
    &lt;/template>
&lt;/hb-checkbox>

&lt;HbCheckbox
    v-model="indeterminateCheckbox"
    label="Indeterminate checkbox"
    indeterminate
/>
</pre>
            </hb-card>
        
        </hb-card>

        <hb-card title="Available Slots + Code" class="mt-4 mb-6">
            <hb-form label="Slots" full>

                <hb-checkbox v-model="checkbox12">
                    <template v-slot:label>
                        v-slot:label
                    </template>
                    <template v-slot:tooltipHeader>
                        v-slot:tooltipHeader
                    </template>
                    <template v-slot:tooltipBody>
                        v-slot:tooltipBody
                    </template>
                    <template v-slot:description>
                        v-slot:description
                    </template>
                </hb-checkbox>

                <hb-checkbox v-model="checkbox13">
                    <template v-slot:content>
                        <v-row no-gutters>
                            <div class="hb-inline-sentence-text">v-slot:content</div>
                            <HbSelect
                                condensed
                                box
                                medium
                                :items="['Office','Fax']"
                            />
                        </v-row>
                    </template>
                </hb-checkbox>
                
            </hb-form>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-checkbox v-model="checkbox12">
    &lt;template v-slot:label>
        v-slot:label
    &lt;/template>
    &lt;template v-slot:tooltipHeader>
        v-slot:tooltipHeader
    &lt;/template>
    &lt;template v-slot:tooltipBody>
        v-slot:tooltipBody
    &lt;/template>
    &lt;template v-slot:description>
        v-slot:description
    &lt;/template>
&lt;/hb-checkbox>

&lt;hb-checkbox v-model="checkbox13">
    &lt;template v-slot:content>
        &lt;v-row no-gutters>
            &lt;div class="hb-inline-sentence-text">v-slot:content&lt;/div>
            &lt;HbSelect
                condensed
                box
                medium
                :items="['Office','Fax']"
            />
        &lt;/v-row>
    &lt;/template>
&lt;/hb-checkbox>
</pre>
            </hb-card>


        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemCheckboxes",
        data: function() {
            return {
                checkbox1: true,
                checkbox2: false,
                checkbox3: true,
                checkbox4: false,
                checkbox5: true,
                checkbox6: false,
                checkbox7: true,
                checkbox8: false,
                checkbox9: true,
                checkbox10: false,
                checkbox11: true,
                checkbox12: false,
                checkbox13: true,
                indeterminateCheckbox: false,
                textField: '',
                selectField: '',
                selectItems: ['Office','Fax'],
                textField2: '',
                selectField2: '',
                selectItems2: ['Office','Fax'],
                textField3: '',
                propHeaders: [
                    { text: 'Name', value: 'name', width: '140' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'label', type: 'string', default: 'undefined', description: 'Sets the label text to the right of the checkbox. An alternative way of setting the label text is by using the "v-slot:label" slot which is useful when you need more complex html/content in the label.' },
                    { name: 'tooltip-header', type: 'string', default: 'undefined', description: 'Sets the label tooltip hover-over header text. You can also use the "v-slot:tooltipHeader" slot to set the tooltip header content (useful for when you need to set more than just a basic string like when you need to set custom html.' },
                    { name: 'tooltip-body', type: 'string', default: 'undefined', description: 'Sets the label tooltip hover-over content text.  You can also use the "v-slot:tooltipBody" slot to set the tooltip body content (useful for when you need to set more than just a basic string like when you need to set custom html.' },
                    { name: 'tooltip-after-content', type: 'boolean', default: 'false', description: 'If set to true, places the tooltip directly after the content slot instead of after the label slot.' },
                    { name: 'description', type: 'string', default: 'undefined', description: 'Sets the description content text which goes below the label/tooltip/input content.  You can also use the "v-slot:description" slot to set the description content (useful for when you need to set more than just a basic string like when you need to set custom html.' },
                    { name: 'readonly', type: 'boolean', default: 'false', description: 'If set to true, this puts the checkbox in readonly mode.' },
                    { name: 'description', type: 'boolean', default: 'false', description: 'If set to true, this puts the checkbox and label in disabled mode. The difference between this and readonly mode is that using disabled will also apply opacity to the label text in addition to the checkbox itself.' },
                    
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'label', description: 'This slot is where you can set the label content. You can also use the "label" prop for this.' },
                    { name: 'tooltipHeader', description: 'This slot is where you can set the tooltip header content.' },
                    { name: 'tooltipBody', description: 'This slot is where you can set the tooltip body content.' },
                    { name: 'content', description: 'This slot is where you can set more complex content to the right of the checkbox. Here is where you would place things such as "<hb-text-field condensed box small>" or "<hb-select condensed box medium>" within a sentence when the design calls for it.' },
                    { name: 'description', description: 'This slot is where you can set description content which goes underneath the label/tooltip/input content.' },
                ]
            };
        }
    }
</script>

<style scoped>

</style>